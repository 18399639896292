import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import StoreContext from "../context/StoreContext";
import { useDropzone } from "react-dropzone";
import api from "../api.js";
import Loader from "../components/Loader";
import { ClipLoader } from "react-spinners";
import StoreOrders from "../components/StoreOrders.js";

const StoreDetail = () => {
  const { id } = useParams(); // Get store ID from URL
  const { stores } = useContext(StoreContext);
  const [store, setStore] = useState(null);
  const [storeLoading, setStoreLoading] = useState(true);
  const [storeError, setStoreError] = useState(null);

  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(true);
  const [filesError, setFilesError] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const navigate = useNavigate();

  // Fetch store details
  const fetchStoreDetails = useCallback(async () => {
    setStoreLoading(true);
    setStoreError(null);
    try {
      const response = await api.get(`/api/stores/${id}`);
      setStore(response.data);
    } catch (error) {
      setStoreError("Failed to fetch store details.");
      console.error("Error fetching store details:", error);
    } finally {
      setStoreLoading(false);
    }
  }, [id]);

  // Fetch store files
  const fetchFiles = useCallback(async () => {
    setFilesLoading(true);
    setFilesError(null);
    try {
      const response = await api.get(`/api/stores/${id}/docs`);

      // Check if response.data.data is an array
      if (response.data && Array.isArray(response.data.data)) {
        setFiles(response.data.data); // Use the correct array
      } else {
        // No files found, set an empty array
        setFiles([]);
        console.warn("No files found or unexpected structure", response.data);
      }
    } catch (error) {
      setFilesError("Failed to fetch files.");
      console.error("Error fetching store files:", error);
    } finally {
      setFilesLoading(false);
    }
  }, [id]);

  useEffect(() => {
    const storeDetails = stores.data.find((s) => s.id.toString() === id);
    if (storeDetails) {
      setStore(storeDetails);
      setStoreLoading(false);
    } else {
      fetchStoreDetails();
    }
    fetchFiles();
  }, [id, stores.data, fetchStoreDetails, fetchFiles]);

  // Handle file drop
  const onDrop = useCallback(
    (acceptedFiles) => {
      const totalFiles = selectedFiles.length + acceptedFiles.length;
      if (totalFiles > 5) {
        setUploadStatus("error");
        setUploadMessage("You can only upload up to 5 files at a time.");
        setTimeout(() => setUploadMessage(""), 5000);
        return;
      }

      // Filter files > 10MB
      const validFiles = acceptedFiles.filter(
        (file) => file.size <= 10 * 1024 * 1024
      );
      if (validFiles.length !== acceptedFiles.length) {
        setUploadStatus("error");
        setUploadMessage("Some files exceeded the 10MB limit.");
        setTimeout(() => setUploadMessage(""), 10000);
      }

      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    },
    [selectedFiles.length]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 5,
  });

  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleUploadFiles = async () => {
    if (selectedFiles.length === 0) {
      setUploadStatus("error");
      setUploadMessage("No files selected for upload.");
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    setIsUploading(true);
    setUploadStatus(null);
    setUploadMessage("");

    try {
      const response = await api.post(`/api/stores/${id}/docs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        setUploadStatus("success");
        setUploadMessage("Files uploaded successfully.");
        setSelectedFiles([]);
        fetchFiles(); // Reload the files list after successful upload
        setTimeout(() => {
          setShowUploadModal(false); // Close modal after success
        }, 2000);
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      setUploadStatus("error");
      setUploadMessage(
        error.response?.data?.error ||
          "Error uploading files. Please try again."
      );
    } finally {
      setIsUploading(false);
    }
  };

  const isImageFile = (fileName) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
    return imageExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  };

  const isPdfFile = (fileName) => {
    return fileName.toLowerCase().endsWith(".pdf");
  };

  return (
    <div className="container mx-auto p-6">
      <div className="mb-6">
        <button
          onClick={() => {
            if (window.history.state && window.history.state.idx > 0) {
              navigate(-1);
            } else {
              navigate("/stores");
            }
          }}
          className="inline-block px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded"
        >
          &larr; Back to Stores
        </button>
      </div>

      {storeLoading ? (
        // Display Loader while fetching store details
        <div className="flex items-center justify-center h-64">
          <Loader
            size={50}
            color="#4A90E2"
            message="Loading store details..."
          />
        </div>
      ) : storeError ? (
        <p className="text-red-500">{storeError}</p>
      ) : store ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Store Details */}
            <div className="md:col-span-1">
              <div className="bg-white shadow-md rounded-lg py-6 px-2">
                {/* Image snippet */}
                {store.original_image && (
                  <div
                    className="mb-4 cursor-pointer"
                    onClick={() => setIsImageModalOpen(true)}
                  >
                    <img
                      src={store.original_image}
                      alt={`${store.title}`}
                      className="w-full h-64 object-cover rounded-lg"
                    />
                  </div>
                )}
                <h1 className="text-2xl font-bold mb-4">{store.title}</h1>
                <div className="mb-4">
                  <p className="text-gray-700 mb-2 text-wrap">
                    <strong>Map Encoded ID:</strong> {store.map_id_encoded}
                  </p>
                  <p className="text-gray-700 mb-2">
                    <strong>Address:</strong> {store.address}
                  </p>
                  <p className="text-gray-700 mb-2">
                    <strong>Phone:</strong> {store.phone}
                  </p>
                  <p className="text-gray-700 mb-2">
                    <strong>Rating:</strong> {store.rating}
                  </p>
                  <p className="text-gray-700 mb-2">
                    <strong>Map Link:</strong>{" "}
                    <a
                      href={store.map_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      View on Map
                    </a>
                  </p>
                </div>
                <div className="flex space-x-2">
                  {/* Button to open the upload modal */}
                  <button
                    onClick={() => setShowUploadModal(true)}
                    className="mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Upload Files
                  </button>

                  {/* New Button: Record Order */}
                  <button
                    onClick={() =>
                      navigate(`/orders/add?storeId=${store.store_id}`)
                    }
                    className="mt-4 w-full bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
                  >
                    Record Order
                  </button>
                </div>
              </div>
            </div>

            {/* Uploaded Files */}
            <div className="md:col-span-2">
              <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-semibold mb-4">Uploaded Files</h2>
                {filesLoading ? (
                  // Display Loader while fetching files
                  <div className="flex items-center justify-center h-32">
                    <Loader
                      size={35}
                      color="#4A90E2"
                      message="Loading files..."
                    />
                  </div>
                ) : filesError ? (
                  <p className="text-red-500">{filesError}</p>
                ) : files.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {files.map((file, index) => {
                      const urlSegments = file.file_url.split("/");
                      const extractedFileName =
                        urlSegments[urlSegments.length - 1];

                      return (
                        <div
                          key={index}
                          className="border rounded-lg overflow-hidden shadow-sm"
                        >
                          {/* Image Preview */}
                          {isImageFile(extractedFileName) ? (
                            <img
                              src={file.file_url}
                              alt={extractedFileName}
                              className="w-full h-48 object-cover"
                            />
                          ) : isPdfFile(extractedFileName) ? (
                            <iframe
                              src={file.file_url}
                              title={extractedFileName}
                              className="w-full h-48"
                            />
                          ) : (
                            <div className="flex items-center justify-center h-48 bg-gray-100">
                              <span className="text-gray-500">
                                No Preview Available
                              </span>
                            </div>
                          )}

                          {/* File Actions */}
                          <div className="p-4">
                            <p className="text-gray-800 mb-2 truncate">
                              {extractedFileName || "Unknown File"}
                            </p>
                            <div className="flex justify-between">
                              <a
                                href={file.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline"
                              >
                                View
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>No files uploaded for this store.</p>
                )}
              </div>
            </div>
          </div>

          {/* Modal for file upload */}
          {showUploadModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full mx-4 p-6 relative">
                <h2 className="text-xl font-semibold mb-4">
                  Upload Files for Store
                </h2>

                {/* Close Modal Button */}
                <button
                  onClick={() => {
                    setShowUploadModal(false);
                    setSelectedFiles([]);
                    setUploadStatus(null);
                    setUploadMessage("");
                  }}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {/* Upload Status */}
                {uploadStatus && (
                  <div
                    className={`mb-4 px-4 py-2 rounded-md shadow-lg ${
                      uploadStatus === "success"
                        ? "bg-green-500 text-white"
                        : "bg-red-500 text-white"
                    }`}
                  >
                    {uploadMessage}
                  </div>
                )}

                <div
                  {...getRootProps({
                    className:
                      "border-dashed border-2 border-gray-400 p-6 rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4",
                  })}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="text-center text-gray-500">
                      Drop the files here ...
                    </p>
                  ) : (
                    <p className="text-center text-gray-500">
                      Drag & drop some files here, or click to select files (Max
                      5 files)
                    </p>
                  )}
                </div>

                {/* Display selected files with option to remove */}
                {selectedFiles.length > 0 && (
                  <ul className="mt-4 space-y-2">
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center bg-gray-100 p-2 rounded"
                      >
                        <span className="text-gray-800">{file.name}</span>
                        <button
                          onClick={() => removeFile(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                )}

                {/* Upload Button and Cancel */}
                <div className="flex justify-end mt-6">
                  <button
                    onClick={() => {
                      setShowUploadModal(false);
                      setSelectedFiles([]);
                      setUploadStatus(null);
                      setUploadMessage("");
                    }}
                    className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleUploadFiles}
                    className={`bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 flex items-center ${
                      isUploading || selectedFiles.length === 0
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    disabled={isUploading || selectedFiles.length === 0}
                  >
                    {isUploading ? (
                      <>
                        <ClipLoader size={20} color="#ffffff" />
                        <span className="ml-2">Uploading...</span>
                      </>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
          <StoreOrders store_id={id} />
          {/* Image Modal */}
          {isImageModalOpen && (
            <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
              <div className="relative">
                <button
                  onClick={() => setIsImageModalOpen(false)}
                  className="absolute top-4 right-4 text-white bg-red-500 rounded-full p-2 hover:bg-red-600 focus:outline-none"
                >
                  ✕
                </button>
                <img
                  src={store.original_image}
                  alt={`${store.title}`}
                  className="max-w-full max-h-screen rounded-lg"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <p>Store not found.</p>
      )}
    </div>
  );
};

export default StoreDetail;
