import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { EventProvider } from "./context/EventContext";
import { HotspotProvider } from "./context/HotspotContext";
import { InventoryProvider } from "./context/InventoryContext";
import { StoreProvider } from "./context/StoreContext";
import { OrderProvider } from "./context/OrderContext";
import { CatalogProvider } from "./context/CatalogContext";
import { BrandProvider } from "./context/BrandContext";

import ProtectedRoute from "./components/ProtectedRoute";
import RequestOTP from "./components/RequestOTP";
import HomePage from "./pages/HomePage";
import Society from "./pages/Hotspot";
import Event from "./pages/Event";
import Store from "./pages/Store";
import StoreDetail from "./pages/StoreDetail";
import Inventory from "./pages/Inventory";
import CatalogDetails from "./pages/CatalogDetail";
import Orders from "./pages/Order";
import AddOrder from "./pages/AddOrder";
import OrderDetail from "./pages/OrderDetail";
import BizomFileUpload from "./pages/BizomFileUpload";
import BrandInventory from "./pages/BrandInventory";
import TaskPage from "./pages/TaskPage";
import TaskDetailsPage from "./pages/TaskDetailsPage";
import NotFound from "./pages/NotFoundPage";

const App = () => {
  useEffect(() => {
    const handleKeydown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "a") {
        // Allow the default "select all" behavior
        e.preventDefault();
        document.execCommand("selectAll");
      }
    };

    // Add event listener for keydown
    window.addEventListener("keydown", handleKeydown);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <AuthProvider>
      <BrandProvider>
        <EventProvider>
          <HotspotProvider>
            <StoreProvider>
              <InventoryProvider>
                <CatalogProvider>
                  <OrderProvider>
                    {" "}
                    {/* Wrap with OrderProvider */}
                    <Routes>
                      {/* Public Route */}
                      <Route path="/login" element={<RequestOTP />} />

                      {/* Protected Routes */}
                      <Route
                        path="/"
                        element={
                          <ProtectedRoute>
                            <HomePage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/hotspots"
                        element={
                          <ProtectedRoute>
                            <Society />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/events"
                        element={
                          <ProtectedRoute>
                            <Event />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/stores"
                        element={
                          <ProtectedRoute>
                            <Store />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/stores/:id"
                        element={
                          <ProtectedRoute>
                            <StoreDetail />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/inventories"
                        element={
                          <ProtectedRoute>
                            <Inventory />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/bizom-file-upload"
                        element={
                          <ProtectedRoute>
                            <BizomFileUpload />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/inventories/brand/:brandId"
                        element={
                          <ProtectedRoute>
                            <BrandInventory />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/tasks"
                        element={
                          <ProtectedRoute>
                            <TaskPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/tasks/:taskType"
                        element={
                          <ProtectedRoute>
                            <TaskDetailsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/inventories/catalog/:catalogId"
                        element={
                          <ProtectedRoute>
                            <CatalogDetails />
                          </ProtectedRoute>
                        }
                      />

                      {/* New Order Routes */}
                      <Route
                        path="/orders"
                        element={
                          <ProtectedRoute>
                            <Orders />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/orders/add"
                        element={
                          <ProtectedRoute>
                            <AddOrder />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/orders/:order_id"
                        element={
                          <ProtectedRoute>
                            <OrderDetail />
                          </ProtectedRoute>
                        }
                      />
                      {/* Catch-all route for non-matching paths */}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </OrderProvider>
                </CatalogProvider>
              </InventoryProvider>
            </StoreProvider>
          </HotspotProvider>
        </EventProvider>
      </BrandProvider>
    </AuthProvider>
  );
};

export default App;
