import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import OrderContext from "../context/OrderContext";
import Loader from "../components/Loader";
import api from "../api";
import UpdateOrderModal from "../components/UpdateOrderModal";

const OrderDetail = () => {
  const { order_id } = useParams();
  const { handleUpdateOrder } = useContext(OrderContext);
  const [order, setOrder] = useState(null);
  const [orderInvoices, setOrderInvoices] = useState([]); // To store invoices
  const [orderLoading, setOrderLoading] = useState(true);
  const [invoicesLoading, setInvoicesLoading] = useState(true);
  const [orderError, setOrderError] = useState(null);
  // eslint-disable-next-line
  const [updateError, setUpdateError] = useState(null);

  const navigate = useNavigate();

  // State for Update Modal
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // Fetch Order Details with Line Items
  const fetchOrderDetails = useCallback(async () => {
    setOrderLoading(true);
    setOrderError(null);
    try {
      // console.log("Fetching order details for order_id:", order_id);
      const response = await api.get(`/api/orders/${order_id}`);
      console.log("Order Details Response:", response.data);
      setOrder(response.data);
    } catch (error) {
      setOrderError(
        error.response?.status === 404
          ? "Order not found."
          : "Failed to fetch order details."
      );
    } finally {
      setOrderLoading(false);
    }
  }, [order_id]);

  const fetchOrderInvoices = useCallback(async () => {
    setInvoicesLoading(true);
    try {
      const response = await api.get(`/api/order-invoice/${order_id}`);
      // console.log("Invoices Response:", response.data);

      if (response.data && Array.isArray(response.data.data)) {
        setOrderInvoices(response.data.data);
      } else {
        console.error("Unexpected invoices response structure:", response.data);
        setOrderInvoices([]);
      }
    } catch (error) {
      console.error("Failed to fetch invoices:", error);
      setOrderInvoices([]);
    } finally {
      setInvoicesLoading(false);
    }
  }, [order_id]);

  // useEffect to load order and invoices
  useEffect(() => {
    const fetchData = async () => {
      await fetchOrderDetails();
      await fetchOrderInvoices();
    };
    fetchData();
  }, [fetchOrderDetails, fetchOrderInvoices]);

  const formatStatus = (status) => {
    if (typeof status !== "string") {
      return "";
    }

    switch (status.toLowerCase()) {
      case "placed":
        return "PLACED";
      case "cancelled":
        return "CANCELLED";
      case "received_inventory":
        return "Received Inventory";
      case "received_invoice":
        return "Received Invoice";
      case "delivered":
        return "DELIVERED";
      default:
        return status;
    }
  };

  // Calculate total amount
  const totalAmount =
    order?.orderLineItems?.reduce((total, item) => {
      const mrp = parseFloat(item.orderLineCatalog.mrp) || 0;
      const quantity = parseInt(item.quantity, 10) || 0;
      return total + mrp * quantity;
    }, 0) || 0;

  // Format currency
  const formatCurrency = (amount) => {
    return `₹${amount.toFixed(2)}`;
  };

  const handleUpdate = () => {
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    // Re-fetch order details to get the updated state
    fetchOrderDetails();
    fetchOrderInvoices();
  };

  // Function to handle the update logic from the modal
  const handleOrderUpdate = async ({
    order_id,
    updates,
    invoiceId,
    invoiceFile,
  }) => {
    try {
      await handleUpdateOrder({ order_id, updates, invoiceId, invoiceFile });
      // Refresh order details after update
      fetchOrderDetails();
      fetchOrderInvoices();
    } catch (error) {
      setUpdateError("Failed to update the order.");
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Back Button and Update Order Button */}
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => navigate(`/orders`)}
          className="inline-block px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded"
        >
          &larr; Back to Orders
        </button>
        {/* Update Order Button */}
        <button
          onClick={handleUpdate}
          className="inline-block px-6 py-2 text-white bg-green-600 hover:bg-green-700 rounded"
        >
          Update Order
        </button>
      </div>

      {orderLoading ? (
        <div className="flex items-center justify-center h-64">
          <Loader
            size={50}
            color="#4A90E2"
            message="Loading order details..."
          />
        </div>
      ) : orderError ? (
        <p className="text-red-500">{orderError}</p>
      ) : order ? (
        <div className="bg-white shadow-md rounded-lg p-8 border border-gray-200">
          {/* Invoice Header */}
          <div className="border-b border-gray-300 pb-4 mb-4">
            <h1 className="text-3xl font-bold mb-2">Invoice</h1>
            <p className="text-gray-600">
              Order ID: <span className="font-semibold">{order.order_id}</span>
            </p>
            <p className="text-gray-600">
              Date of Order:{" "}
              <span className="font-semibold">
                {new Date(order.date_of_order).toLocaleDateString()}
              </span>
            </p>
            <p className="text-gray-600">
              Sales Person:{" "}
              <span className="font-semibold">{order.sales_person}</span>
            </p>
            <p className="text-gray-600">
              State:{" "}
              <span className="font-semibold">{formatStatus(order.state)}</span>
            </p>
            <p className="text-gray-600">
              Fulfillment type:{" "}
              <span className="font-semibold">
                {formatStatus(order.fulfillment_type)}
              </span>
            </p>
            <p className="text-gray-600">
              Comments:{" "}
              <span className="font-semibold">
                {formatStatus(order.comments)}
              </span>
            </p>
          </div>

          {/* Store and Brand Information */}
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">
              Store & Brand Information
            </h2>
            <p>
              <strong>Store Id:</strong>{" "}
              {order.orderStore ? order.store_id : "N/A"}
            </p>
            <p>
              <strong>Store:</strong>{" "}
              {order.orderStore ? order.orderStore.title : "N/A"}
            </p>
            <p>
              <strong>Address:</strong>{" "}
              {order.orderStore ? order.orderStore.address : "N/A"}
            </p>
            <p>
              <strong>Brand:</strong>{" "}
              {order.orderBrand ? order.orderBrand.name : "N/A"}
            </p>
          </div>

          {/* Order Invoices */}
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Order Invoices</h2>
            {invoicesLoading ? (
              <div className="flex items-center justify-center h-32">
                <Loader
                  size={35}
                  color="#4A90E2"
                  message="Loading invoices..."
                />
              </div>
            ) : orderInvoices.length === 0 ? (
              <p>No invoices uploaded for this order.</p>
            ) : (
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b text-left">#</th>
                    <th className="py-2 px-4 border-b text-left">Invoice ID</th>
                    <th className="py-2 px-4 border-b text-center">
                      Invoice Link
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderInvoices.map((invoice, index) => (
                    <tr
                      key={invoice.invoice_id}
                      className="border-b hover:bg-gray-100"
                    >
                      <td className="py-2 px-4">{index + 1}</td>
                      <td className="py-2 px-4">
                        {invoice.invoiceOrderInvoice.invoice_ref}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {invoice.invoiceOrderInvoice.documentsInvoice.length >
                        0 ? (
                          <a
                            href={
                              invoice.invoiceOrderInvoice.documentsInvoice[0]
                                .file_url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline cursor-pointer"
                          >
                            View Invoice
                          </a>
                        ) : (
                          <span>No document available</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* Line Items */}
          <h2 className="text-xl font-semibold mt-4 mb-2">Items</h2>
          {order.orderLineItems ? (
            <table className="min-w-full bg-white border border-gray-200 mb-6">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b text-left">#</th>
                  <th className="py-2 px-4 border-b text-left">Product Name</th>
                  <th className="py-2 px-4 border-b text-left">Quantity</th>
                  <th className="py-2 px-4 border-b text-left">Amount</th>
                </tr>
              </thead>
              <tbody>
                {order.orderLineItems.map((item, index) => {
                  const mrp = parseFloat(item.orderLineCatalog.mrp);
                  const quantity = parseInt(item.quantity, 10);
                  const validMrp = isNaN(mrp) ? 0 : mrp;
                  const validQuantity = isNaN(quantity) ? 0 : quantity;
                  const amount = validMrp * validQuantity;

                  return (
                    <tr key={item.id} className="border-b hover:bg-gray-100">
                      <td className="py-2 px-4">{index + 1}</td>
                      <td className="py-2 px-4">
                        {item.orderLineCatalog
                          ? item.orderLineCatalog.product_name
                          : "N/A"}
                      </td>
                      <td className="py-2 px-4">{validQuantity}</td>
                      <td className="py-2 px-4">{formatCurrency(amount)}</td>
                    </tr>
                  );
                })}

                {/* Total Amount Row */}
                {order.orderLineItems.length > 0 && (
                  <tr className="bg-gray-100">
                    <td className="py-2 px-4 font-semibold" colSpan="3">
                      Total Amount
                    </td>
                    <td className="py-2 px-4 font-semibold">
                      {formatCurrency(totalAmount)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <p>No line items found.</p>
          )}
        </div>
      ) : (
        <p>Order not found.</p>
      )}

      {/* Update Modal */}
      {showUpdateModal && order && (
        <UpdateOrderModal
          isOpen={showUpdateModal}
          onClose={handleCloseUpdateModal}
          order={order}
          onUpdate={handleOrderUpdate}
        />
      )}
    </div>
  );
};

export default OrderDetail;
