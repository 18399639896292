import React, { useEffect, useState } from "react";
import api from "../api";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

const StoreOrders = ({ store_id }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderCount, setOrderCount] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`/api/orders/stores/${store_id}`);
        const ordersWithTotalQuantity = response.data.data.map((order) => ({
          ...order,
          total_quantity: order.orderLineItems.reduce(
            (sum, item) => sum + item.quantity,
            0
          ),
        }));
        setOrderCount(response.data.count)
        setOrders(ordersWithTotalQuantity);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [store_id]);

  const handleRowClick = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  const formatStatus = (status) => {
    switch (status.toLowerCase()) {
      case "placed":
        return "PLACED";
      case "cancelled":
        return "CANCELLED";
      case "received_inventory":
        return "RECEIVED INVENTORY";
      case "received_invoice":
        return "RECEIVED INVOICE";
      case "delivered":
        return "DELIVERED";
      default:
        return status;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader size={50} color="#4A90E2" message="Loading orders..." />
      </div>
    );
  }

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  if (orders.length === 0) {
    return <p className="text-center">No orders found for this store.</p>;
  }

  return (
    <div className="container mx-auto p-6 border shadow-md rounded-lg mt-4">
      <h2 className="text-2xl font-semibold">Orders for Store</h2>
      <h3 className=" mb-6">Total Orders: {orderCount}</h3>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
              Order ID
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
              Store Name
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
              Brand Name
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
              Date of Order
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
              Total Quantity
            </th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
              State
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {orders.map((order, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(order.order_id)}
              className="cursor-pointer hover:bg-gray-100"
            >
              <td className="px-4 py-2 text-sm text-gray-700">
                {order.order_id}
              </td>
              <td className="px-4 py-2 text-sm text-gray-700">
                {order.orderStore?.title || "N/A"}
              </td>
              <td className="px-4 py-2 text-sm text-gray-700">
                {order.orderBrand?.name || "N/A"}
              </td>
              <td className="px-4 py-2 text-sm text-gray-700">
                {new Date(order.date_of_order).toLocaleDateString()}
              </td>
              <td className="px-4 py-2 text-sm text-gray-700">
                {order.total_quantity}
              </td>
              <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                {formatStatus(order.state)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StoreOrders;
