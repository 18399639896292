import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api";
import Navbar from "../components/Navbar";

const TaskDetailsPage = () => {
  const { taskType } = useParams();
  const [task, setTask] = useState(null);
  const [store, setStore] = useState(null);
  const [remainingTasks, setRemainingTasks] = useState(0);
  const [holdTasks, setHoldTasks] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [storeType, setStoreType] = useState("");
  const [businessStatus, setBusinessStatus] = useState("");
  const navigate = useNavigate();

  const storeTypeOptions = [
    "National Modern Trade", //DMart, Reliance, More
    "Hypermarket", //M.K Retail, Aishwarya, Ratandeep
    "Supermarket", //City Mart,
    "Mini Market", //Self pickup, 300-400sqft
    "Departmental Store", //Next door Grocery store
    "Society Store", //Store within society
    "Pharma Store",
    "Organic Store", //Primarliy organic, positioned as such
    "Pet Store",
    "Baby Store",
    "Beauty Store",
  ];

  const businessStatusOptions = ["Operational", "Not-Operational"];

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await api.get(`/api/tasks/nextStore?type=${taskType}`);
        if (response.data.task && response.data.store) {
          setTask(response.data.task);
          setStore(response.data.store);
          setRemainingTasks(response.data.remainingTasks);
          setHoldTasks(response.data.holdTasks);
        } else {
          setError("No more tasks available.");
        }
      } catch (err) {
        setError("Error fetching task.");
      } finally {
        setLoading(false);
      }
    };

    fetchTask();
  }, [taskType]);

  const handleResolveTask = async () => {
    if (!storeType || !businessStatus) {
      setError("Please select both Store Type and Business Status.");
      return;
    }

    try {
      const payload = {
        store_type: storeType,
        business_status: businessStatus,
      };
      await api.post(`/api/tasks/${task.task_id}/resolveStore`, payload);
      navigate(0); // Reload for the next task
    } catch (err) {
      setError("Error resolving task.");
    }
  };

  const handleSkipTask = async () => {
    try {
      await api.post(`/api/tasks/${task.task_id}/skip`);
      navigate(0);
    } catch (err) {
      setError("Error skipping task.");
    }
  };

  if (loading)
    return <div className="p-6 text-center">Loading task details...</div>;

  if (error)
    return (
      <div className="p-6 text-center">
        <p className="text-red-600">{error}</p>
      </div>
    );

  return (
    <>
      <Navbar />
      <div className="p-6 bg-gray-100 min-h-screen">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Task Details</h1>

        {/* Task Counts */}
        <div className="flex justify-between items-center mb-6">
          <div className="text-lg text-gray-700 flex flex-col">
            <p>
              <strong>Remaining Tasks:</strong> {remainingTasks}
            </p>
            <p>
              <strong>Tasks on Hold:</strong> {holdTasks}
            </p>
          </div>
        </div>

        {store && (
          <div className="bg-white shadow-md rounded-md p-6 mb-6">
            {/* Store Image */}
            {store.original_image && (
              <div className="mb-6 flex justify-center">
                <img
                  src={store.original_image}
                  alt="Store"
                  className="max-w-sm h-auto rounded-md shadow-md"
                />
              </div>
            )}

            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Store Information
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">
                  <strong>Store ID:</strong> {store.store_id}
                </p>
                <p className="text-gray-600">
                  <strong>Title:</strong> {store.title}
                </p>
                <p className="text-gray-600">
                  <strong>City:</strong> {store.city}
                </p>
                <p className="text-gray-600">
                  <strong>Map ID:</strong> {store.map_id_encoded}
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <strong>Address:</strong> {store.address}
                </p>
                <p className="text-gray-600">
                  <strong>Phone:</strong> {store.phone}
                </p>
                <a
                  href={store.map_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  View on Map
                </a>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white shadow-md rounded-md p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Resolve Task
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-2">
                Store Type
              </label>
              <select
                value={storeType}
                onChange={(e) => setStoreType(e.target.value)}
                className="w-full px-3 py-2 border rounded-md shadow-sm"
              >
                <option value="">Select Store Type</option>
                {storeTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Business Status
              </label>
              <select
                value={businessStatus}
                onChange={(e) => setBusinessStatus(e.target.value)}
                className="w-full px-3 py-2 border rounded-md shadow-sm"
              >
                <option value="">Select Business Status</option>
                {businessStatusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={handleResolveTask}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Resolve Task
          </button>
          <button
            onClick={handleSkipTask}
            className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
          >
            Skip Task
          </button>
          <button
            onClick={() => navigate("/tasks")}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
          >
            Back to Tasks
          </button>
        </div>
      </div>
    </>
  );
};

export default TaskDetailsPage;
