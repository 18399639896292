import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ShoppingBagIcon,
  ArchiveBoxIcon,
  ClipboardDocumentListIcon,
  DocumentArrowUpIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";

const actions = [
  {
    title: "Hotspot Portal",
    path: "/hotspots",
    icon: BuildingOffice2Icon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    title: "Event Portal",
    path: "/events",
    icon: CalendarDaysIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    title: "Store Portal",
    path: "/stores",
    icon: ShoppingBagIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    title: "Inventory Portal",
    path: "/inventories",
    icon: ArchiveBoxIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
  },
  {
    title: "Orders Portal",
    path: "/orders",
    icon: ClipboardDocumentListIcon,
    iconForeground: "text-green-700",
    iconBackground: "bg-green-50",
  },
  {
    title: "Bizom File Upload",
    path: "/bizom-file-upload",
    icon: DocumentArrowUpIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "Tasks Portal",
    path: "/tasks",
    icon: ClipboardIcon,
    iconForeground: "text-blue-700",
    iconBackground: "bg-blue-50",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="home-page flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        Welcome to the Operations Portal
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        Please choose a portal to continue:
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl">
        {actions.map((action, idx) => (
          <div
            key={idx}
            onClick={() => handleNavigate(action.path)}
            className="cursor-pointer bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow duration-300 relative"
          >
            <div className="flex items-center">
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  "inline-flex rounded-lg p-3 ring-4 ring-white"
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
              <h2 className="text-xl font-semibold text-gray-800 ml-4">
                {action.title}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
