import React, { useState, useContext, useEffect, useRef } from "react";
import OrderContext from "../context/OrderContext";
import StoreContext from "../context/StoreContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CatalogContext from "../context/CatalogContext";
import AuthContext from "../context/AuthContext";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import debounce from "lodash.debounce";
import api from "../api";

const AddOrder = () => {
  const { addOrder } = useContext(OrderContext);
  const { searchStores } = useContext(StoreContext);
  const { catalogs, fetchCatalogsByBrandId, clearCatalogs } =
    useContext(CatalogContext);
  const { user } = useContext(AuthContext);

  const [store_id, setStoreId] = useState("");
  const [storeOptions, setStoreOptions] = useState([]);
  const [storeLoading, setStoreLoading] = useState(false);
  const [dateOfOrder, setDateOfOrder] = useState(new Date());
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const prefillStoreId = queryParams.get("storeId");

  const [menuIsOpen, setMenuIsOpen] = useState(false); // State to control dropdown

  const debouncedFetchStores = useRef(
    debounce(async (inputValue) => {
      if (!inputValue || inputValue.trim() === "") {
        setStoreOptions([]);
        setMenuIsOpen(false); // Close menu if input is empty
        return;
      }
      setStoreLoading(true);
      try {
        const stores = await searchStores(inputValue);
        const options = stores.map((store) => ({
          value: String(store.store_id),
          label: `${String(store.title)} - ${String(store.address)}`,
        }));
        setStoreOptions(options);
        setMenuIsOpen(true); // Open menu after fetching
      } catch (error) {
        console.error("Error fetching stores:", error);
        setStoreOptions([]);
        setMenuIsOpen(false); // Close menu on error
      } finally {
        setStoreLoading(false);
      }
    }, 500)
  ).current;

  const [brand_id, setBrandId] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);

  const [lineItems, setLineItems] = useState([]);
  const [salesPerson, setSalesPerson] = useState(user?.username || "");
  const [comments, setComments] = useState(""); // Added comments state
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const salesPersonList = [
    { id: 1, name: "Mohd" },
    { id: 2, name: "Faizan" },
  ];
  const navigate = useNavigate();

  // Fetch all brands on component mount
  useEffect(() => {
    const fetchAllBrands = async () => {
      try {
        const brands = await api.get(`/api/brands`);
        const options = brands.data.data.map((brand) => ({
          value: String(brand.brand_id),
          label: String(brand.name),
        }));
        setBrandOptions(options);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchAllBrands();

    // Clean up the debounced functions on unmount
    return () => {
      debouncedFetchStores.cancel();
    };
    // eslint-disable-next-line
  }, []);

  // Clean up the debounced functions on unmount
  useEffect(() => {
    return () => {
      debouncedFetchStores.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddLineItem = () => {
    setLineItems([...lineItems, { catalog_id: "", quantity: 1 }]);
  };

  const handleRemoveLineItem = (index) => {
    const updatedLineItems = lineItems.filter((_, i) => i !== index);
    setLineItems(updatedLineItems);
  };

  const handleLineItemChange = (index, field, value) => {
    const updatedLineItems = lineItems.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setLineItems(updatedLineItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!store_id || !brand_id || lineItems.length === 0) {
      setErrorMessage("Store, Brand, and at least one Line Item are required.");
      return;
    }

    // Validate line items
    for (let item of lineItems) {
      if (!item.catalog_id || !item.quantity || item.quantity <= 0) {
        setErrorMessage(
          "All line items must have a catalog and a positive quantity."
        );
        return;
      }
    }

    const orderData = {
      store_id,
      brand_id,
      line_items: lineItems,
      sales_person: salesPerson,
      comments,
      date_of_order: dateOfOrder.toISOString(),
    };

    setLoading(true);
    try {
      const result = await addOrder(orderData);
      setSuccessMessage(
        `Order added successfully! New order ID: ${result.order_id}`
      );

      // Scroll to top before navigating
      window.scrollTo({ top: 0, behavior: "smooth" });

      // Redirect to orders page after 3 seconds
      setTimeout(() => {
        navigate(`/orders`);
      }, 3000);
    } catch (error) {
      console.error("Error adding order:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "Failed to add the order. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  // Prefill store selection if storeId is present in URL
  useEffect(() => {
    if (prefillStoreId) {
      setStoreId(prefillStoreId);

      // Fetch and add the store to the dropdown
      const fetchAndAddStore = async () => {
        try {
          const response = await api.get(`/api/stores/${prefillStoreId}`);
          const store = response.data;

          if (store) {
            const newOption = {
              value: String(store.store_id), // Ensure using store.store_id
              label: `${store.title} - ${store.address}`,
            };

            // Add the store to storeOptions if not already present
            setStoreOptions((prevOptions) => {
              if (
                !prevOptions.find((option) => option.value === newOption.value)
              ) {
                return [...prevOptions, newOption];
              }
              return prevOptions;
            });

            // Open the dropdown menu automatically
            setMenuIsOpen(true);
          }
        } catch (error) {
          console.error("Error fetching store details:", error);
        }
      };

      fetchAndAddStore();
    }
  }, [prefillStoreId]);

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-6">
        <div className="mb-6">
          <button
            onClick={() => navigate(-1)}
            className="inline-block px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded"
          >
            &larr; Back to Orders
          </button>
        </div>

        <h1 className="text-2xl font-bold mb-4">Add New Order</h1>

        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        {successMessage && (
          <p className="text-green-500 mb-4">{successMessage}</p>
        )}

        <form onSubmit={handleSubmit}>
          {/* Store Selection */}
          <div className="mb-4">
            <label className="block text-gray-700">Select Store</label>
            <ReactSelect
              name="store_id"
              value={
                store_id
                  ? storeOptions.find((option) => option.value === store_id) ||
                    null
                  : null
              }
              onInputChange={(inputValue, { action }) => {
                if (action === "input-change") {
                  debouncedFetchStores(inputValue); // Fetch stores based on input
                }
                return inputValue;
              }}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setStoreId(selectedOption.value);

                  // Optionally add the selected store to the options list if it isn't already present
                  if (
                    !storeOptions.some(
                      (option) => option.value === selectedOption.value
                    )
                  ) {
                    setStoreOptions((prevOptions) => [
                      ...prevOptions,
                      selectedOption,
                    ]);
                  }

                  // Close the menu when a selection is made
                  setMenuIsOpen(false);
                } else {
                  setStoreId("");
                }
              }}
              options={storeOptions} // The options fetched from the backend
              isLoading={storeLoading} // Show loader while fetching
              placeholder="Search and select a store"
              noOptionsMessage={() =>
                storeLoading ? "Loading..." : "No stores. Try searching again!"
              }
              isClearable
              filterOption={null} // Disable internal filtering
              menuIsOpen={menuIsOpen} // Control the dropdown open state
              onMenuClose={() => setMenuIsOpen(false)}
            />
          </div>

          {/* Brand Selection */}
          <div className="mb-4">
            <label className="block text-gray-700">Select Brand</label>
            <select
              className="w-full border p-2"
              value={brand_id}
              onChange={async (e) => {
                const selectedBrandId = e.target.value;
                setBrandId(selectedBrandId);

                if (selectedBrandId) {
                  // Fetch catalogs for the selected brand
                  await fetchCatalogsByBrandId(selectedBrandId);
                  setLineItems([]);
                } else {
                  setBrandId("");
                  setLineItems([]); // Clear line items if no brand selected
                  clearCatalogs(); // Clear catalogs
                }
              }}
              required
            >
              <option value="">-- Select Brand --</option>
              {brandOptions.map((brand) => (
                <option key={brand.value} value={brand.value}>
                  {brand.label}
                </option>
              ))}
            </select>
          </div>

          {/* Date of Order */}
          <div className="mb-4">
            <label className="block text-gray-700">Date of Order</label>
            <DatePicker
              selected={dateOfOrder}
              onChange={(date) => setDateOfOrder(date)}
              className="w-full border p-2"
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              required
            />
          </div>

          {/* Sales Person */}
          <div className="mb-4">
            <label className="block text-gray-700">Sales Person</label>
            <select
              className="w-full border p-2"
              value={salesPerson}
              onChange={(e) => setSalesPerson(e.target.value)}
              required
            >
              <option value="">Select Sales Person</option>
              {salesPersonList.map((person) => (
                <option key={person.id} value={person.name}>
                  {person.name}
                </option>
              ))}
            </select>
          </div>

          {/* Comments */}
          <div className="mb-4">
            <label className="block text-gray-700">Comments</label>
            <textarea
              className="w-full border p-2"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter any comments for the order"
              rows={4}
            ></textarea>
          </div>

          {/* Line Items */}
          <div className="mb-4">
            <h2 className="text-xl font-semibold mb-2">Line Items</h2>
            {lineItems.map((item, index) => (
              <div key={index} className="border p-4 mb-2 rounded">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold">Item {index + 1}</h3>
                  <button
                    type="button"
                    onClick={() => handleRemoveLineItem(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
                <div className="mb-2">
                  <label className="block text-gray-700">Select Catalog</label>
                  <select
                    className="w-full border p-2"
                    value={item.catalog_id}
                    onChange={(e) =>
                      handleLineItemChange(index, "catalog_id", e.target.value)
                    }
                    required
                  >
                    <option value="">-- Select Catalog --</option>
                    {catalogs.data.map((catalog) => (
                      <option
                        key={catalog.catalog_id}
                        value={catalog.catalog_id}
                      >
                        {catalog.product_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700">Quantity</label>
                  <input
                    type="number"
                    className="w-full border p-2"
                    value={item.quantity}
                    onChange={(e) =>
                      handleLineItemChange(index, "quantity", e.target.value)
                    }
                    min="1"
                    required
                  />
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={handleAddLineItem}
              className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              disabled={catalogs.data.length === 0} // Disable if no catalogs
            >
              + Add Line Item
            </button>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="mr-2 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 flex items-center ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader size={20} color="#ffffff" message="" />
                  <span className="ml-2">Submitting...</span>
                </>
              ) : (
                "Add Order"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddOrder;
