import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";

const taskTypes = [
  {
    id: "store-classification",
    name: "Store Classification",
    description: "Categorize stores based on their type and status.",
  },
  // Add more task types with descriptions as needed
];

const TaskPage = () => {
  const navigate = useNavigate();

  const handleTaskTypeClick = (taskType) => {
    navigate(`/tasks/${taskType}`);
  };

  return (
    <>
      <Navbar />
      <div className="p-6 bg-gray-100 min-h-screen">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Available Tasks
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {taskTypes.map((task) => (
            <div
              key={task.id}
              onClick={() => handleTaskTypeClick(task.id)}
              className="cursor-pointer bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow duration-300 relative"
            >
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {task.name}
              </h2>
              <p className="text-gray-600">{task.description}</p>
              {/**              <div className="flex justify-end mt-4">
                <button
                  className="px-4 py-2 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the card click
                    handleTaskTypeClick(task.id);
                  }}
                >
                  Start Task →
                </button>
              </div>*/}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TaskPage;
